h1, h2, h3, h4, h5, h6 {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    color: #f7f7f7;
    font-size: xx-large;
  }

  body {
    background-color: #2a5168;
    color: #f7f7f7;
    font-family: 'Roboto', sans-serif;
    
  font-size: 1rem; /* adjust the font size as needed */
  }

  table {
    border-collapse: collapse;
  }
  
  td {
    padding: 0.5rem;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
  }

  th {
    padding: 0.5rem;
    border: 1px solid #ccc;
    background-color: #f7f7f7;
    font-weight: bold;
    color: black;
    text-align: left;
  }